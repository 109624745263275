import { Refresh, X } from "tabler-icons-react";




export function NotificationContainer(props) {
    const { children, fetchCustomerNotifications, connectionStatus, userData, apiKey, hide } = props

    return (
        <div className="h-full">
            <div className="max-w-xl bg-white">
                <div className="sticky top-0 z-50 opacity-100 bg-white">
                    <div className="grid grid-cols-12 px-4 pt-4 pb-4 shadow shadow-indigo-400">
                        <div className="relative col-span-10 flex flex-row">
                            <p className="text-sm text-gray-800 font-medium">Notifications</p>
                            <span className={
                                connectionStatus === "Connecting" ? "text-yellow-500"
                                    : connectionStatus === "Open" ? "text-green-500"
                                        : "text-red-500"
                            }>
                                <span className="absolute flex h-2 w-2">
                                    <span className={`animate-ping absolute inline-flex h-full w-full rounded-full 
                                ${connectionStatus === "Connecting" ? "bg-yellow-400"
                                            : connectionStatus === "Open" ? "bg-green-400"
                                                : "bg-red-400"
                                        } opacity-75`}></span>

                                    <span className={`absolute inline-flex rounded-full h-2 w-2 ${connectionStatus === "Connecting" ? "bg-yellow-500"
                                        : connectionStatus === "Open" ? "bg-green-500"
                                            : "bg-red-500"
                                        }`}></span>
                                </span>
                            </span>
                        </div>
                        <div className="cursor-pointer" onClick={() => {
                            const payload = { ...userData, apiKey: apiKey }
                            fetchCustomerNotifications(payload)
                        }}>
                            <p className="text-xs text-gray-600 hover:text-indigo-500"><Refresh size={18} /></p>
                        </div>
                        <div className="cursor-pointer" onClick={hide}>
                            <p className="text-xs text-gray-600 hover:text-red-500"><X size={18} /></p>
                        </div>
                    </div>
                </div>

                {children}

            </div>
        </div>
    )
}
