// import './App.css';
import { MantineProvider } from '@mantine/core';
import { MainWidget } from './widget/notifications/NotificationWidget';

function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <MainWidget />
    </MantineProvider>
  );
}

export default App;
