/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import axios from "axios";
import { Center, Divider, Popover } from "@mantine/core";
import { Dots } from "tabler-icons-react";
import { NotificationContainer } from "./Layout";

// const baseRt = [
//     {
//         version: "v1",
//         publicId: "some-random-string",
//         workspace: "first-workspace",
//         customer: "the-right-customer",
//         campaign: "no need campaign",
//         flowStep: "the freaking flow step",
//         customerPublicId: "customerPublicId",
//         title: "Your first notifications",
//         text: "This is your first notification",
//         deepLink: "/go home",
//         isRead: true,
//     },
//     {
//         version: "v1",
//         publicId: "some-random-string-1",
//         workspace: "first-workspace",
//         customer: "the-right-customer",
//         campaign: "no need campaign",
//         flowStep: "the freaking flow step",
//         customerPublicId: "customerPublicId",
//         title: "Your second notifications",
//         text: "This is your second notification",
//         deepLink: "/go home",
//         isRead: false,
//     },
// ]

export function MainWidget() {
    const [socketChanged, setSocketChanged] = useState(false)
    // const socketUrl = 'wss://h425jvv651.execute-api.us-west-2.amazonaws.com/production'
    const socketUrl = 'wss://krszuzi4oa.execute-api.us-west-1.amazonaws.com/production'
    const {
        // sendMessage,
        sendJsonMessage,
        // lastMessage,
        lastJsonMessage,
        readyState,
        // getWebSocket,
    } = useWebSocket(socketUrl, {
        onOpen: () => {
            setSocketChanged(!socketChanged)
            console.log('Welcome to userhq app inbox. Send automated notifications to your users - https://userhq.io')
        },
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    const [apiKey, setApiKey] = useState("");
    const [userData, setUserData] = useState({});
    const [apiCheckStatus, setApiCheckStatus] = useState("unchecked") //invalid || valid
    const [allNotifications, setAllNotifications] = useState()



    //notify the lambda function on reconnect
    useEffect(() => {
        const { userId, userEmail } = userData
        // console.log({ userIdx: userId, userEmailx: userEmail })
        if (!userId && !userEmail) return;
        sendJsonMessage({
            "action": "otherMessage",
            "message": { eventType: "identify", payload: { ...userData } }
        })
        fetchCustomerNotifications({ ...userData, apiKey })
    }, [socketChanged, userData])


    useEffect(() => {
        console.log(`userhq.io inbox is currently: ${readyState} - https://userhq.io`)
    }, [readyState])



    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString); // doesn't work in IE, but who cares ;)
        const apiKey = urlParams.get("api-key");
        if (apiKey) {
            setApiKey(apiKey);
            checkAPIKey(apiKey)
            return;
        }
        if (!apiKey) setApiCheckStatus("invalid")
    }, []);


    useEffect(() => {
        const handleMessage = evt => {
            if (!evt?.data) return;
            if (evt.data.hasOwnProperty("indentifyMsg")) {
                const indentifyMsg = evt?.data?.indentifyMsg
                // apiCheckStatus
                setUserData({ ...indentifyMsg })
                const payload = { ...indentifyMsg, apiKey }
                fetchCustomerNotifications(payload)
            }
        };
        window.addEventListener("message", handleMessage);
        return () => window.removeEventListener("message", handleMessage);
    }, [apiCheckStatus, apiKey, fetchCustomerNotifications, sendJsonMessage]);


    useEffect(() => {
        if (lastJsonMessage !== null) {
            // console.log({ lastJsonMessage })
            if (lastJsonMessage.hasOwnProperty("identify") || lastJsonMessage.hasOwnProperty("title")) {
                fetchCustomerNotifications({ ...userData, apiKey })
                // console.log("fetchCustomerNotifications should be called now")
                // const newArray = [...allNotifications, lastJsonMessage]
                // setAllNotifications((prev) => [...newArray]);
                // setAllNotifications((prev) => [(lastJsonMessage), ...prev]);
            }
        }
    }, [lastJsonMessage, setAllNotifications]);



    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState]


    const handleClickSendMessage = async (route, messageId) => {
        if (route === "#" || route === "/" || route.length < 4) {
            // send message
            // console.log({ route, messageId })
            await handleOptions("markAsRead", messageId)
        }
        else {
            window.parent.location.href = route
            await handleOptions("markAsRead", messageId)
        }

    }


    const handleOptions = async (type, messageId) => {
        if (type === "markAsRead" || type === "markAsUnRead") {
            const copiedArray = [...allNotifications]
            const shouldRead = (type === "markAsRead") ? true : false
            // console.log({ shouldRead, type })
            const editedArray = await copiedArray.map((notObj) => {
                if (notObj.publicId === messageId) {
                    notObj.isRead = shouldRead
                }
                return notObj
            })
            // console.log({ editedArray })
            setAllNotifications(editedArray)

            sendJsonMessage({
                "action": "otherMessage",
                "message": { eventType: type, payload: { messageId: messageId } }
            })
        }
        else if (type === "delete") {
            const copiedArray = [...allNotifications]
            // console.log({ copiedArray, messageId })
            const removeTheInitial = copiedArray.filter(notObj => notObj.publicId !== messageId)
            setAllNotifications(removeTheInitial)
            sendJsonMessage({
                "action": "otherMessage",
                "message": { eventType: "deleteMessage", payload: { messageId: messageId } }
            })
        }
    }

    async function checkAPIKey(apiKey) {
        try {
            // const apiUrl = `http://localhost:5005/v1/apikey/validate`
            const apiUrl = `https://api.userhq.io/v1/apikey/validate`
            const axiosResult = await axios.post(`${apiUrl}`, { "keyType": "inbox" }, {
                headers: {
                    Authorization: `Bearer ${apiKey}`//'Bearer ing_eocoirunrh3fltlnpk5iwnci2z1yxllc'
                }
            })
            const { isValid } = axiosResult?.data
            if (!isValid) setApiCheckStatus("invalid")
            else setApiCheckStatus("valid")
        } catch (error) {
            console.log(`userhq error: ${error?.message}`)
        }


    }

    async function fetchCustomerNotifications(payload) {
        try {
            // console.log({ payloadRTx: payload })
            const { apiKey: initialAPIkey } = payload
            const { userId, userEmail } = payload
            // console.log({ userId, userEmail })
            if (!userId && !userEmail) return;
            // console.log("POST creap 3")
            if (!initialAPIkey) return;
            const fetchAxios = await axios.post(`https://api.userhq.io/v1/notification/list`, { ...payload }, {
                headers: {
                    Authorization: `Bearer ${initialAPIkey ? initialAPIkey : apiKey}`//'Bearer ing_eocoirunrh3fltlnpk5iwnci2z1yxllc'
                }
            })
            // console.log({ fetchAxiosRT: fetchAxios })
            if (!fetchAxios) return;
            const { notifications } = fetchAxios.data
            // console.log({ notifications })
            const newCopiedNotifications = JSON.parse(JSON.stringify(notifications))
            const reversedList = newCopiedNotifications //.reverse()
            setAllNotifications(() => [...reversedList])
        } catch (error) {
            console.log(`userhq error: ${error?.message}`)
        }
    }

    const hide = () => {
        window.parent.postMessage("hide", "*");
    }

    if (!apiKey || apiCheckStatus === "unchecked") return <div className="content-center  justify-center pt-48 px-48">
        <div className="content-center  justify-center">
            <div className="animate-ping rounded-full w-14 h-14 bg-gradient-to-tr from-indigo-500 to-pink-500"></div>
        </div>
    </div>

    // if (apiCheckStatus === "invalid" && !apiKey) return <>Invalid API Key</>

    if (!userData?.userId && !userData?.userEmail) {
        // const { userId, userEmail } = userData
        return (
            <div>
                <NotificationContainer
                    fetchCustomerNotifications={fetchCustomerNotifications}
                    connectionStatus={connectionStatus}
                    userData={userData}
                    apiKey={apiKey}
                    hide={hide}
                >
                    <Center>
                        <div className="pt-10 pl-4">
                            <Center>
                                <p className="text-xl text-gray-900 font-medium">Invalid user</p>
                            </Center>
                            <p className="text-sm text-gray-500 font-medium">Add the correct user identification (email or usid). <a href="https://userhq.io/docs" target="_blank" rel="noreferrer" className="text-indigo-600">Learn more about user identification</a></p>
                        </div>
                    </Center>
                </NotificationContainer>
            </div>
        )
    }

    if (apiCheckStatus === "invalid" || !apiKey) {
        return (
            <div>
                <NotificationContainer
                    fetchCustomerNotifications={fetchCustomerNotifications}
                    connectionStatus={connectionStatus}
                    userData={userData}
                    apiKey={apiKey}
                    hide={hide}
                >
                    <Center>
                        <div className="pt-10 pl-4">
                            <Center>
                                <p className="text-xl text-gray-900 font-medium">Invalid APP ID</p>
                            </Center>
                            <p className="text-sm text-gray-500 font-medium">Add the correct APP ID. <a href="https://userhq.io/docs" target="_blank" rel="noreferrer" className="text-indigo-600">Learn more about app id</a></p>
                        </div>
                    </Center>
                </NotificationContainer>
            </div>
        )
    }


    if ((!allNotifications || allNotifications.length < 1) && apiKey) {
        return (
            <div>
                <NotificationContainer
                    fetchCustomerNotifications={fetchCustomerNotifications}
                    connectionStatus={connectionStatus}
                    userData={userData}
                    apiKey={apiKey}
                    hide={hide}
                >
                    <Center>
                        <div className="pt-10 pl-4">
                            <Center>
                                <p className="text-xl text-gray-900 font-medium">Notifications</p>
                            </Center>
                            <p className="text-sm text-gray-500 font-medium">Notifications will appear here when you receive them</p>
                        </div>
                    </Center>
                </NotificationContainer>
            </div>
        )
    }



    return (
        <div>

            <NotificationContainer
                fetchCustomerNotifications={fetchCustomerNotifications}
                connectionStatus={connectionStatus}
                userData={userData}
                apiKey={apiKey}
                hide={hide}
            >
                <div className="bg-gray-200">
                    {allNotifications.reverse().map((inNoty, index) => {
                        const { publicId, title, text, deepLink, isRead } = inNoty
                        return (
                            <div key={`${index}-${publicId}`} className="bg-white py-2 px-4">
                                <div className="grid grid-cols-12">
                                    <div className="col-span-11 cursor-pointer font-medium" onClick={() => handleClickSendMessage(deepLink, publicId)} >
                                        <div>
                                            <p className={!isRead ? "text-gray-800 font-bold text-md" : "text-gray-600 text-md"}>{title}</p>
                                        </div>
                                        <div className="pb-2">
                                            <p className={!isRead ? "text-gray-700 font-bold text-sm" : "text-gray-500 text-sm"}>{text}</p>
                                        </div>
                                    </div>
                                    <div className="text-xs text-gray-500 cursor-pointer">
                                        <Popover width={200} position="bottom" withArrow shadow="md">
                                            <Popover.Target>
                                                <button>
                                                    <Dots />
                                                </button>
                                            </Popover.Target>
                                            <Popover.Dropdown className="bg-white font-medium">
                                                <div className="">
                                                    <div
                                                        onClick={() => handleOptions(isRead ? "markAsUnRead" : "markAsRead", publicId)}
                                                        className="text-gray-800 px-1 py-1 hover:bg-indigo-100">
                                                        <p>Mark as {isRead ? "unread" : "read"}</p>
                                                    </div>
                                                    <div
                                                        onClick={() => handleOptions("delete", publicId)}
                                                        className="text-red-800 px-1 py-1 hover:bg-red-100">
                                                        <p>Delete</p>
                                                    </div>
                                                </div>
                                            </Popover.Dropdown>
                                        </Popover>
                                    </div>
                                </div>

                                <Divider />
                            </div>
                        )
                    })}
                </div>
            </NotificationContainer>
        </div>
    )
}






